import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyCtREu0X_trXeXB5zDaYdS2n43rE0QYc1U",
  authDomain: "werkapp-de.firebaseapp.com",
  databaseURL: "https://werkapp-sales.europe-west1.firebasedatabase.app/",
  projectId: "werkapp-de",
  storageBucket: "werkapp-de.appspot.com",
  messagingSenderId: "489912178576",
  appId: "1:489912178576:web:97b6d1a2f9ba3abf604515",
  measurementId: "G-BV8S0P4JJ5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {provider: new ReCaptchaV3Provider('6LdM_XohAAAAAGuzEnhU7h7e7Us35qpRssg2uAlY'),isTokenAutoRefreshEnabled: true});
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const database = getDatabase(app);

export { app as firebase,
  appCheck,
  analytics,
  perf,
  database };