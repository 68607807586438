import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import { InputGroup } from "react-bootstrap";

const Events = ({handleSave}) => {

  const days = ["Montag","Dienstag","Mittwoch","Donnerstag","Freitag", "Samstag"];
  
  const [data, setData] = useState({name: "", email: "", tel: "", plz:"", von: "08:00", bis: "19:00"});
  const [wochentage, setWochentage] = useState(days);
  const [validated, setValidated] = useState(false);
  
  const handleCheckboxChange = (event) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;
    setWochentage((prevWochentage) => {
      if (isChecked) {
        return [...prevWochentage, checkboxName];
      } else {
        return prevWochentage.filter((name) => name !== checkboxName);
      }
    });
  };

  const handleChange = e => {
    setData(prev => ({...prev, [e.target.name]: e.target.value}));
  }

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    handleSave({...data, wochentage});
  }

  return (
    <Container>
      <Row className="justify-content-center mt-4">
        <Col xs={12} md={9} lg={6}>
          <Card>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Card.Body>
              <p className="my-2 fs-3 fw-bold text-center"><span className="text-primary">Werk</span><span className="text-accent1">APP</span> SalesPartner</p>
              <p className="text-center">Super, dass Du Deine Chancen als WerkAPP SalesPartner kennenlernen willst. 
                Bitte wähle einen Zeitraum, in dem wir Dich für einen Videocall kontaktieren können.</p>
              <hr/>
                <p className="my-2 fw-semibold">Wann dürfen wir Dich kontaktieren?</p>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Form.Text>Wochentage</Form.Text>
                    {
                      days.map((day, index) => <Form.Check key={index} checked={(wochentage.includes(day))} label={day} name={day} type="checkbox" id={day} onChange={handleCheckboxChange} />)
                    }
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className="mb-2">
                    <Form.Text>Von</Form.Text>
                    <InputGroup>
                      <Form.Control type="time" name="von" min="08:00" max="19:00" value={data.von} onChange={handleChange} required/>
                      <InputGroup.Text>Uhr</InputGroup.Text>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-2">
                    <Form.Text>Bis</Form.Text>
                    <InputGroup>
                      <Form.Control type="time" name="bis" min="09:00" max="20:00" value={data.bis} onChange={handleChange} required/>
                      <InputGroup.Text>Uhr</InputGroup.Text>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <hr/>
                <p className="my-2 fw-semibold">Deine Kontaktdaten</p>
                
                <FormGroup className="mb-3">
                  <Form.Text>Dein Name</Form.Text>
                  <Form.Control name="name" value={data.name} onChange={handleChange} required />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Text>Deine E-Mail Adresse</Form.Text>
                  <Form.Control type="email" name="email" value={data.email} onChange={handleChange} required />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Text>Deine Telefonnummer (gerne WhatsAPP)</Form.Text>
                  <Form.Control type="tel" name="tel" value={data.tel} onChange={handleChange} required />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Text>Deine Verkaufsregion</Form.Text>
                  <Form.Control name="plz" value={data.plz} onChange={handleChange} placeholder="Bsp: 11xxx, 12xxx - 124xx" />
                </FormGroup>
                <p className="text-muted" style={{fontSize: "12px"}}>Deine Daten werden ausschließlich zur Kontaktaufnahme verwendet und vertraulich behandelt. Deine Daten werden nicht an Dritte weitergegeben.</p>
              </Card.Body>
              <Card.Footer align="right">
                <Button type="submit">Anmelden</Button>
              </Card.Footer>
              </Form>
            </Card>
          </Col>
        </Row>
      
    <p style={{margin:"12px 0",textAlign:"center", fontSize:"10px", color:"rgba(255, 255, 255, .6)"}}>Verantwortliche Herausgeberin: WerkAPP Vertriebs GmbH | Kontakt: info@werkapp.de</p>
    </Container>
  )
}

export default Events