import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const Thanks = () => {
  return (
    <Container>
      <Row className="justify-content-center my-4">
        <Col xs={12} md={9} lg={6}>
          <Card>
            <Card.Body>
              <p className="my-2 fs-3 fw-semibold text-center"><span className="text-primary">Werk</span><span className="text-accent1">APP</span> SalesPartner</p>
              <p>Danke für deine Anmeldung.</p>
              <p>Wir werden dich in Kürze kontaktieren.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Thanks