import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const Error = () => {
  return (
    <Container>
      <Row className="justify-content-center my-4">
        <Col xs={12} md={9} lg={6}>
          <Card>
            <Card.Body>
              <p className="my-2 fs-3 fw-semibold text-center">Oups...</p>
              <p>Es scheint, als hat deine Anmeldung nicht funktioniert.</p>
              <p>Gerne kannst du uns direkt kontaktieren: <a href="mailto=salespartner@werkapp.de">salespartner@werkapp.de</a></p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Error