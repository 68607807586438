import { useState } from "react";
import { getDatabase, push, ref, serverTimestamp } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";
import Meetings from "./Meetings"
import Thanks from "./Thanks";
import Error from "./Error";

const App = () => {
  const analytics = getAnalytics();
  const db = getDatabase();
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const handleSave = (d) => {
    push(ref(db, "applicants/"),{
      added: serverTimestamp(),
      name: (d.name === "" ? null : d.name),
      email: d.email,
      tel: d.tel,
      plz: d.plz,
      wochentage: d.wochentage,
      von: d.von,
      bis: d.bis,
    }).then(() => {
      logEvent(analytics, "sign_up");
      setSent(true);
    }).catch(err => {
      console.error(err);
      setError(true);
    });
  }
  if(error) return <Error />;

  if(sent) return <Thanks />;

  return <Meetings handleSave={handleSave} />;
}

export default App